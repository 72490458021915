import React from "react";
import { Typography, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

function RedirectSpin() {
  return (
    <div>
      <Typography.Title level={3} className="header">
        支付跳转中
      </Typography.Title>
      <div className="container" style={{ minHeight: "200px" }}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 80 }} spin />} />
      </div>
    </div>
  );
}

export default RedirectSpin;
