import React, { useEffect, useState } from "react";
import { Spin, message, Modal } from "antd";
import request from "@/utils/request";

import RealNameForm from "./RealNameForm";
import CardForm from "./CardForm";
import QRCodeForm from "./QRCodeForm";
import FormPost from "./FormPost";
import RedirectSpin from "./RedirectSpin";

const order_no = new URL(window.location).searchParams.get("order_no");
// const order_no = "OR1728057650652012";
function isMobile() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const mobileRegex =
    /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i;
  return mobileRegex.test(userAgent.toLowerCase());
}

export const fullScreenHandle = (href = window.location.href) => {
  let a = document.createElement("a");
  a.href = href;
  a.click();
};
function Client() {
  const [loading, setLoading] = useState(false);
  const [showType, setShowType] = useState("card"); // card, name, redirect, qrcode, form-post
  const [order, setOrder] = useState({});

  const handleSetShowType = data => {
    let tempShowType = showType;
    let credentialType = data?.credential?.type || "";

    if (!isMobile()) {
      // PC: 全部显示二维码
      tempShowType = "qrcode";
    } else {
      // H5: 保持原样逻辑
      if (credentialType === "redirect") {
        tempShowType = "redirect";
        data.credential?.url
          ? fullScreenHandle(data.credential.url)
          : message.error("跳转失败");
      } else if (credentialType === "form-post") {
        tempShowType = "form-post";
      } else if (credentialType === "card" && !data.payer_name) {
        tempShowType = "name";
      } else if (credentialType === "card" && data.payer_name) {
        tempShowType = "card";
      } else if (credentialType === "qrcode") {
        // 将二维码转换成跳转
        tempShowType = "redirect";
        data.credential?.url
          ? fullScreenHandle(data.credential.url)
          : message.error("跳转失败");
      } else {
        tempShowType = "none"; // 默认处理非 "redirect" 的情况
      }
    }

    setShowType(tempShowType);
  };

  // 取得訂單
  const fetchOrder = async () => {
    setLoading(true);
    const res = await request({
      url: "/openapi/order/" + order_no,
      method: "get",
    });
    setLoading(false);
    return res;
  };

  // 送出
  const postPayerName = async ({ payer_name }) => {
    setLoading(true);
    const { status, data } = await request({
      url: "/openapi/order/" + order_no + "/payer/name",
      method: "post",
      data: { payer_name },
    });
    setLoading(false);
    if (status !== 200) return;
    if (data.status === "failed") {
      Modal.error({
        title: "实名失败，请联系客服",
        okText: "我知道了",
      });
      return;
    }
    setOrder(data);
    handleSetShowType(data);
  };

  const initProcess = async () => {
    const { status, data } = await fetchOrder();
    if (status !== 200) return;
    setOrder(data);
    if (data.status === "failed") {
      Modal.error({
        title: "抽卡失败，请重新下单或联系客服",
        okText: "我知道了",
      });
      return;
    }
    handleSetShowType(data);
  };

  useEffect(() => {
    order_no && initProcess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="global-layout">
        <div className="container">
          <div className="info-card">
            <Spin spinning={loading}>
              {/* name type */}
              {showType === "name" && (
                <RealNameForm postPayerName={postPayerName} />
              )}
              {/* card type */}
              {showType === "card" && <CardForm order={order} />}
              {/* form-post type */}
              {showType === "form-post" && (
                <FormPost formData={order?.credential?.data || {}} />
              )}
              {/* redirect type */}
              {showType === "redirect" && <RedirectSpin />}
              {/* qrcode type*/}
              {showType === "qrcode" && (
                <QRCodeForm
                  payType={order?.pay_type}
                  credentialData={order?.credential?.data}
                  url={order?.credential?.url || ""}
                  expired_at={order?.expired_at || 0}
                />
              )}
            </Spin>
          </div>
        </div>
      </div>
    </>
  );
}

export default Client;
