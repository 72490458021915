import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import "@/less/clientMain.less";
import Client from "@/views/Client";
import { interceptor } from "@/utils/request";
interceptor();

function App() {
  return <Client />;
}

export default App;
